import {FC, ReactNode} from 'react';
import styled from 'styled-components';

import {HoobiizWhiteLabelingAuthType} from '@shared/dynamo_model';
import {FrontendTheme} from '@shared/frontends/frontend_theme_model';
import {arrayJoin} from '@shared/lib/array_utils';
import {HoobiizWhiteLabelingEnriched} from '@shared/lib/hoobiiz/hoobiiz_media_enriched';

import {HoobiizMediaView} from '@shared-frontend/components/auth/hoobiiz_media_view';
import {UnthemedNavLink} from '@shared-frontend/components/core/button';

export type ModeHeroAuthWhiteLabeling = HoobiizWhiteLabelingEnriched['auth'] & {
  type: HoobiizWhiteLabelingAuthType.ModeHero;
};

interface ModeHeroTemplateProps {
  whiteLabeling: ModeHeroAuthWhiteLabeling;
  children: ReactNode;
}

const withOpacity = (color: string, opacity: string): string => {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  return color.slice(0, 7) + opacity;
};

export function modeHeroInputOverrides(
  whiteLabeling: ModeHeroAuthWhiteLabeling
): Partial<FrontendTheme['input']> {
  const res: Partial<FrontendTheme['input']> = {
    borderWidth: 2,
    focusBorderWidth: 2,
    focusOutlineColor: 'transparent',
    focusOutlineWidth: 0,
    borderRadius: 8,

    fontSize: 18,
    fontWeight: 500,
    fontFamily: undefined,
    titleMarginBottom: 6,
    paddingRight: 16,
    paddingLeft: 16,
    height: 52,
    labelOpacity: 0.8,
  };

  if (whiteLabeling.inputTextColor !== undefined) {
    res.textColor = whiteLabeling.inputTextColor;
    res.textColorDisabled = whiteLabeling.inputTextColor;
    res.focusTextColor = whiteLabeling.inputTextColor;
  }

  if (whiteLabeling.inputBackgroundColor !== undefined) {
    res.backgroundColor = whiteLabeling.inputBackgroundColor;
    res.backgroundColorHover = whiteLabeling.inputBackgroundColor;
    res.backgroundColorFocus = whiteLabeling.inputBackgroundColor;
    res.backgroundColorDisabled = whiteLabeling.inputBackgroundColor;
  }

  if (whiteLabeling.inputBorderColor !== undefined) {
    res.borderColor = withOpacity(whiteLabeling.inputBorderColor, '55');
    res.hoverBorderColor = withOpacity(whiteLabeling.inputBorderColor, '99');
    res.focusBorderColor = withOpacity(whiteLabeling.inputBorderColor, 'cc');
  }

  return res;
}

export function modeHeroButtonOverrides(
  whiteLabeling: ModeHeroAuthWhiteLabeling
): Partial<FrontendTheme['button']> {
  const res: Partial<FrontendTheme['button']> = {};

  if (whiteLabeling.buttonTextColor !== undefined) {
    res.textColorActive = whiteLabeling.buttonTextColor;
    res.textColorHover = whiteLabeling.buttonTextColor;
    res.textColorDisabled = whiteLabeling.buttonTextColor;
    res.textColorLoading = whiteLabeling.buttonTextColor;
  }

  if (whiteLabeling.buttonBackgroundColor !== undefined) {
    res.backgroundActive = whiteLabeling.buttonBackgroundColor;
    res.backgroundHover = whiteLabeling.buttonBackgroundColor;
    res.backgroundDisabled = `${whiteLabeling.buttonBackgroundColor}aa`;
    res.backgroundLoading = `${whiteLabeling.buttonBackgroundColor}aa`;
  }

  return res;
}

export function modeHeroNavLinkOverrides(
  whiteLabeling: ModeHeroAuthWhiteLabeling
): Partial<FrontendTheme['link']> {
  const res: Partial<FrontendTheme['link']> = {
    fontWeight: 500,
    focusBorderWidth: 3,
  };

  if (whiteLabeling.linkTextColor !== undefined) {
    res.textColorActive = whiteLabeling.linkTextColor;
    res.textColorHover = whiteLabeling.linkTextColor;
    res.textColorDisabled = `${whiteLabeling.linkTextColor}66`;
    res.textColorLoading = `${whiteLabeling.linkTextColor}66`;
    res.focusBorderColor = `${whiteLabeling.linkTextColor}cc`;
  }

  return res;
}

export function modeHeroCheckboxOverrides(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  whiteLabeling: ModeHeroAuthWhiteLabeling
): Partial<FrontendTheme['checkbox']> {
  return {};
}

export const ModeHeroTemplate: FC<ModeHeroTemplateProps> = props => {
  const {whiteLabeling, children} = props;
  const {hero, logo, title, bgGradient1, bgGradient2, textColor, titleColor} = whiteLabeling;

  return (
    <Wrapper $color={textColor}>
      <Left $bgGradient1={bgGradient1} $bgGradient2={bgGradient2}>
        {logo ? (
          <LogoContainer to="/">
            <HoobiizMediaView
              media={logo}
              cover={false}
              size={{width: '100%', height: '100%'}}
              objectPosition={'right'}
            />
          </LogoContainer>
        ) : (
          <></>
        )}
        {title !== undefined ? (
          <Title $color={titleColor}>
            {arrayJoin(
              title.split('\n').map(line => <span key={line}>{line}</span>),
              i => (
                <br key={i} />
              )
            )}
          </Title>
        ) : (
          <></>
        )}
        {children}
      </Left>
      <Right>
        {hero ? (
          <HoobiizMediaView
            media={hero}
            size={{width: '100%', height: '100%'}}
            cover
            objectPosition={'right'}
          />
        ) : (
          <></>
        )}
      </Right>
    </Wrapper>
  );
};

const COLUMN_LAYOUT_THRESHOLD_PX = 600;
const COLUMN_LAYOUT_THRESHOLD_VW = COLUMN_LAYOUT_THRESHOLD_PX / 100;
ModeHeroTemplate.displayName = 'ModeHeroTemplate';

const Wrapper = styled.div<{$color?: string}>`
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #ccc;
  color: ${p => p.$color ?? p.theme.main.textColor};
  font-weight: 400;
`;
/* eslint-disable @typescript-eslint/no-magic-numbers */
const Left = styled.div<{$bgGradient1?: string; $bgGradient2?: string}>`
  height: 100%;
  width: 100%;
  max-width: ${COLUMN_LAYOUT_THRESHOLD_PX}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: safe center;
  overflow-y: auto;
  background: linear-gradient(
    to right,
    ${p => p.$bgGradient1 ?? p.theme.main.backgroundColor},
    ${p => p.$bgGradient2 ?? p.theme.main.backgroundColor}
  );

  flex-shrink: 0;
  @media (max-width: ${COLUMN_LAYOUT_THRESHOLD_PX}px) {
    flex-shrink: unset;
    flex-grow: 1;
  }

  gap: 6%;
  padding: ${10 * COLUMN_LAYOUT_THRESHOLD_VW}px;
  @media (max-width: ${COLUMN_LAYOUT_THRESHOLD_PX}px) {
    gap: 10vw;
    padding: 6.4vw;
  }
`;

const Title = styled.div<{$color?: string}>`
  font-weight: bold;
  color: ${p => p.$color ?? p.theme.main.textColor};
  text-align: center;
  line-height: 1.5;

  font-size: ${5.5 * COLUMN_LAYOUT_THRESHOLD_VW}px;
  @media (max-width: ${COLUMN_LAYOUT_THRESHOLD_PX}px) {
    font-size: calc(5.5vw);
  }
`;

const LogoContainer = styled(UnthemedNavLink)`
  width: ${70 * COLUMN_LAYOUT_THRESHOLD_VW}px;
  @media (max-width: ${COLUMN_LAYOUT_THRESHOLD_PX}px) {
    width: 80vw;
  }
`;
/* eslint-enable @typescript-eslint/no-magic-numbers */

const Right = styled.div`
  flex-grow: 1;
  @media (max-width: ${COLUMN_LAYOUT_THRESHOLD_PX}px) {
    display: none;
  }
`;
