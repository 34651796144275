import {FC, useCallback, useState} from 'react';
import {useTheme} from 'styled-components';

import {
  EmailString,
  HoobiizWhiteLabeling,
  HoobiizWhiteLabelingAuthType,
} from '@shared/dynamo_model';
import {HoobiizMediaEnriched} from '@shared/lib/hoobiiz/hoobiiz_media_enriched';
import {DeepPartial} from '@shared/lib/type_utils';

import {sharedApiCall} from '@shared-frontend/api';
import {ForgotPasswordPageModeDefault} from '@shared-frontend/components/auth/mode_default/forgot_password_page_theme_0';
import {ForgotPasswordPageModeHero} from '@shared-frontend/components/auth/mode_hero/forgot_password_page_mode_hero';
import {ModeHeroAuthWhiteLabeling} from '@shared-frontend/components/auth/mode_hero/mode_hero_template';
import {notifyError} from '@shared-frontend/lib/notification';
import {ComponentClass} from '@shared-frontend/lib/react';

interface ForgotPasswordPageProps {
  wrapper: ComponentClass | ComponentClass[] | undefined;
  whiteLabeling: HoobiizMediaEnriched<DeepPartial<HoobiizWhiteLabeling>> | undefined;
}

export type ForgotPasswordFormMode = 'initial' | 'finish';

export const ForgotPasswordPage: FC<ForgotPasswordPageProps> = props => {
  const {wrapper, whiteLabeling} = props;
  const {
    main: {api},
  } = useTheme();

  const [email, setEmail] = useState('' as EmailString);
  const [formMode, setFormMode] = useState<ForgotPasswordFormMode>('initial');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleEmailSubmit = useCallback(() => {
    setIsSubmitting(true);
    sharedApiCall(api, '/forgot-password', {email})
      .then(data => {
        if (data.success) {
          setFormMode('finish');
        } else {
          notifyError(`Échec de l'envoi de l'email`);
        }
      })
      .catch(err => notifyError(err))
      .finally(() => setIsSubmitting(false));
  }, [api, email]);

  if (whiteLabeling?.auth?.type === HoobiizWhiteLabelingAuthType.ModeHero) {
    const whiteLabelingModeHero = whiteLabeling.auth as ModeHeroAuthWhiteLabeling;
    return (
      <ForgotPasswordPageModeHero
        whiteLabeling={whiteLabelingModeHero}
        email={email}
        setEmail={setEmail}
        formMode={formMode}
        handleEmailSubmit={handleEmailSubmit}
        isSubmitting={isSubmitting}
      />
    );
  }

  return (
    <ForgotPasswordPageModeDefault
      wrapper={wrapper}
      email={email}
      setEmail={setEmail}
      formMode={formMode}
      handleEmailSubmit={handleEmailSubmit}
      isSubmitting={isSubmitting}
    />
  );
};
ForgotPasswordPage.displayName = 'ForgotPasswordPage';
