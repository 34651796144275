import {CommitHash} from '@shared/dynamo_model';
import {EnvConstants} from '@shared/model/env_constants';

export const COMMIT_HASH = 'b6046b0ffdf6f48e9cb7f77683f747921a849772' as CommitHash;
export const IS_LOCALHOST_ENV = false as boolean;
export const IS_DEVELOPMENT_ENV = false as boolean;
export const IS_PRODUCTION_ENV = true as boolean;
export const env: EnvConstants = {
  type: 'prod',
  region: 'eu-west-1',
  onescaleDomainName: 'onescale.io',
  cidrBlock: '10.3.0.0/16',
  accountId: 'azq3l25a765kd2ol',
  stripe: {
    apiPublishableKey:
      'pk_live_51NxCNdCS2V4e2nHuTjnHPk34iQ4l6qY694W7FSG5yxA1CeMGqqCR3RcrJNATRO7wOCAWJ4eyKd39Q4IueaVoOtog00ATAk47VI',
    liveMode: true,
  },
  expertTicket: {
    baseUrl: 'https://api.portaventuraworld.experticket.com/api',
    partnerId: '8ja1sdeifghtc',
    apiVersion: '3.54',
  },
  googleMapsApiKey: 'AIzaSyDQ3VD_ybEGMknTAUxQNaIvVnxbbR45lPU',
} as EnvConstants;
