import {useEffect, useMemo} from 'react';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizMediaId} from '@shared/dynamo_model';
import {IS_LOCALHOST_ENV} from '@shared/env_constants';
import {HoobiizMediaEnriched} from '@shared/lib/hoobiiz/hoobiiz_media_enriched';
import {uidUnsafe} from '@shared/lib/rand';
import {removeUndefined} from '@shared/lib/type_utils';
import {SanitizedItem} from '@shared/model/search_tables';

import {apiCall} from '@shared-frontend/api';
import {enrichHoobiizMedia, extractHoobiizMediaIds} from '@shared-frontend/lib/hoobiiz_media';
import {createMapStore} from '@shared-frontend/lib/map_data_store';

const hoobiizMediaStore = createMapStore<HoobiizMediaId, SanitizedItem<'HoobiizMedia'>>();
const getHoobiizMedia = hoobiizMediaStore.getData;
const setHoobiizMedia = hoobiizMediaStore.setData;

const NO_MEDIA_ID = uidUnsafe() as HoobiizMediaId;

export function useHoobiizMedia(
  opts?: SanitizedItem<'HoobiizMedia'>
): SanitizedItem<'HoobiizMedia'> | undefined {
  const {id, media} = opts ?? {};

  // If a full media item is provided, we update the store (if it's new)
  useEffect(() => {
    if (id !== undefined && media !== undefined && getHoobiizMedia(id) !== media) {
      setHoobiizMedia(id, media);
    }
  }, [id, media]);

  // Get the media from the store
  const current = hoobiizMediaStore.useData(id ?? NO_MEDIA_ID);

  // If we don't have the media, we trigger a load
  useEffect(() => {
    if (id !== undefined && !current) {
      // Trigger a load if not available
      getOrFetchHoobiizMedia({mediaId: id}).catch(() => {});
    }
  }, [current, id]);

  // Return the media
  return opts?.media ?? current;
}

export function useHoobiizMedias(opts: {
  mediaIds: HoobiizMediaId[];
}): SanitizedItem<'HoobiizMedia'>[] | undefined {
  const {mediaIds} = opts;
  const current = hoobiizMediaStore.useAllData();

  // On first call, trigger a load if not available
  useEffect(() => {
    const initialData = hoobiizMediaStore.getAllData();
    for (const mediaId of mediaIds) {
      if (!initialData.has(mediaId)) {
        getOrFetchHoobiizMedia({mediaId}).catch(() => {});
      }
    }
  }, [mediaIds]);

  // Map all the mediaIds to their corresponding store item
  const media = useMemo(() => {
    const media: SanitizedItem<'HoobiizMedia'>[] = [];
    for (const mediaId of mediaIds) {
      const mediaItem = current.get(mediaId) ?? {id: mediaId};
      media.push(mediaItem);
    }
    return media;
  }, [current, mediaIds]);

  return media;
}

export function useHoobiizMediaEnriched<T>(data: T): HoobiizMediaEnriched<T> {
  const mediaIds = useMemo(() => extractHoobiizMediaIds(data), [data]);
  const mediaArr = useHoobiizMedias({mediaIds});
  const mediaByIds = useMemo(() => {
    const m = mediaArr?.map(m => (m.media ? ([m.id, m.media] as const) : undefined)) ?? [];
    return new Map(removeUndefined(m));
  }, [mediaArr]);
  return enrichHoobiizMedia(data, mediaByIds);
}

//

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const FETCH_LOOP_TIMEOUT_MS = IS_LOCALHOST_ENV ? 500 : 5000;

const mediaFetchAwaiters = new Map<
  HoobiizMediaId,
  {resolve: (group: SanitizedItem<'HoobiizMedia'>) => void; reject: (err: unknown) => void}[]
>();
async function getOrFetchHoobiizMedia(opts: {
  mediaId: HoobiizMediaId;
}): Promise<SanitizedItem<'HoobiizMedia'>> {
  return new Promise<SanitizedItem<'HoobiizMedia'>>((resolve, reject) => {
    // Check if already in the store
    const {mediaId} = opts;
    const groupStoreItem = getHoobiizMedia(mediaId);
    if (groupStoreItem) {
      resolve(groupStoreItem);
      return;
    }

    // Not in the store, register as an awaiter
    let awaiters = mediaFetchAwaiters.get(mediaId);
    if (!awaiters) {
      awaiters = [];
      mediaFetchAwaiters.set(mediaId, awaiters);
    }
    awaiters.push({resolve, reject});
    if (awaiters.length > 1) {
      return; // someone is already fetching
    }

    // Fetch the group data
    apiCall(HoobiizApi, '/get-media', {mediaId})
      .then(({item}) => {
        if (item) {
          setHoobiizMedia(mediaId, item);
          for (const {resolve} of mediaFetchAwaiters.get(mediaId) ?? []) {
            resolve(item);
          }
          mediaFetchAwaiters.set(mediaId, []);
        } else {
          setTimeout(() => {
            getOrFetchHoobiizMedia({mediaId}).catch(() => {});
          }, FETCH_LOOP_TIMEOUT_MS);
          for (const {reject} of mediaFetchAwaiters.get(mediaId) ?? []) {
            reject(new Error(`Media ${mediaId} not found`));
          }
          mediaFetchAwaiters.set(mediaId, []);
        }
      })
      .catch(err => {
        setTimeout(() => {
          getOrFetchHoobiizMedia({mediaId}).catch(() => {});
        }, FETCH_LOOP_TIMEOUT_MS);
        for (const {reject} of mediaFetchAwaiters.get(mediaId) ?? []) {
          reject(err);
        }
        mediaFetchAwaiters.set(mediaId, []);
      });
  });
}
