import QRious from 'qrious';
import {useEffect} from 'react';
import styled from 'styled-components';

import {Custom} from '@shared-frontend/lib/react';
import {optionalPx} from '@shared-frontend/lib/styled_utils';

interface QrCodeProps {
  value: string;
  size?: number;
  margin?: {
    top?: number | string;
    right?: number | string;
    bottom?: number | string;
    left?: number | string;
  };
  padding?: number;
  borderRadius?: number | string;
  background?: string;
  backgroundAlpha?: number;
  foreground?: string;
  foregroundAlpha?: number;
}

const DEFAULT_SIZE = 250;

export const QrCode: Custom<QrCodeProps, 'div'> = ({
  value,
  size = DEFAULT_SIZE,
  margin,
  padding,
  borderRadius,
  background,
  backgroundAlpha,
  foreground,
  foregroundAlpha,
  ...rest
}) => {
  useEffect(() => {
    const element = document.getElementById('qrcode');
    // eslint-disable-next-line no-null/no-null
    if (element !== null) {
      // eslint-disable-next-line no-new
      new QRious({
        element,
        value,
        size,
        padding,
        background,
        backgroundAlpha,
        foreground,
        foregroundAlpha,
      });
    }
  }, [value, size, padding, background, backgroundAlpha, foreground, foregroundAlpha]);

  return (
    <Wrapper
      {...rest}
      $marginTop={margin?.top}
      $marginRight={margin?.right}
      $marginBottom={margin?.bottom}
      $marginLeft={margin?.left}
      $borderRadius={borderRadius}
    >
      {
        // eslint-disable-next-line react/forbid-dom-props
        <canvas id="qrcode" />
      }
    </Wrapper>
  );
};
QrCode.displayName = 'QrCode';

const Wrapper = styled.div<{
  $marginTop?: number | string;
  $marginRight?: number | string;
  $marginBottom?: number | string;
  $marginLeft?: number | string;
  $borderRadius?: number | string;
}>`
  display: flex;
  ${p => optionalPx('margin-top', p.$marginTop)}
  ${p => optionalPx('margin-right', p.$marginRight)}
  ${p => optionalPx('margin-bottom', p.$marginBottom)}
  ${p => optionalPx('margin-left', p.$marginLeft)}
  ${p => optionalPx('border-radius', p.$borderRadius)}
  overflow: hidden;
`;
