import {FC, useMemo} from 'react';
import styled, {useTheme} from 'styled-components';

import {
  EmailString,
  FrontendUserDataContent,
  HoobiizUserGroupMemberCardConfig,
} from '@shared/dynamo_model';

import {FrontendUserDataForm} from '@shared-frontend/components/auth/frontend_user_data_form';
import {
  ModeHeroAuthWhiteLabeling,
  modeHeroButtonOverrides,
  modeHeroCheckboxOverrides,
  modeHeroInputOverrides,
  modeHeroNavLinkOverrides,
  ModeHeroTemplate,
} from '@shared-frontend/components/auth/mode_hero/mode_hero_template';
import {Button, NavLink} from '@shared-frontend/components/core/button';
import {Input, InputProps} from '@shared-frontend/components/core/input_v2';

interface RegisterPageModeHeroProps {
  whiteLabeling: ModeHeroAuthWhiteLabeling;
  memberCardConfig: HoobiizUserGroupMemberCardConfig | undefined;
  confirmationEmailSent: boolean;
  emailInputProps: InputProps<EmailString | undefined>;
  passwordInputProps: InputProps<string | undefined>;
  userData?: Partial<FrontendUserDataContent>;
  setUserData: (userData: Partial<FrontendUserDataContent> | undefined) => void;
  handleRegisterSubmit: () => Promise<void>;
}

export const RegisterPageModeHero: FC<RegisterPageModeHeroProps> = props => {
  const {
    whiteLabeling,
    memberCardConfig,
    confirmationEmailSent,
    emailInputProps,
    passwordInputProps,
    userData,
    setUserData,
    handleRegisterSubmit,
  } = props;
  const {
    auth: {userDataContentType},
  } = useTheme();

  const {inputOverrides, buttonOverrides, navLinkOverrides, checkboxOverrides} = useMemo(() => {
    return {
      inputOverrides: modeHeroInputOverrides(whiteLabeling),
      buttonOverrides: modeHeroButtonOverrides(whiteLabeling),
      navLinkOverrides: modeHeroNavLinkOverrides(whiteLabeling),
      checkboxOverrides: modeHeroCheckboxOverrides(whiteLabeling),
    };
  }, [whiteLabeling]);

  return (
    <ModeHeroTemplate whiteLabeling={whiteLabeling}>
      <Form>
        {confirmationEmailSent ? (
          <Message $color={whiteLabeling.textColor ?? ''}>
            {`Un email de confirmation vous a été envoyé à l'email ${emailInputProps.value}. Vous
            pouvez fermer cette page.`}
          </Message>
        ) : (
          <>
            <FormFields>
              <FormInput>
                <Input
                  {...emailInputProps}
                  overrides={inputOverrides}
                  placeholder="Votre adresse mail"
                />
              </FormInput>
              <FormInput>
                <Input
                  {...passwordInputProps}
                  overrides={inputOverrides}
                  placeholder="Votre mot de passe"
                />
              </FormInput>
              <FrontendUserDataForm
                userDataContentType={userDataContentType}
                data={userData}
                inputTheme={inputOverrides}
                checkboxTheme={checkboxOverrides}
                onChange={setUserData}
                memberCardConfig={memberCardConfig}
              />
            </FormFields>
            <LegalText>
              En continuant, vous reconnaissez avoir lu les{' '}
              <NavLink to="/terms" overrides={navLinkOverrides}>
                mentions légales
              </NavLink>
            </LegalText>
            <Button
              expand
              type="button"
              submit
              onClickAsync={handleRegisterSubmit}
              overrides={buttonOverrides}
            >
              Créer mon compte
            </Button>
            <BottomLinks>
              <div></div>
              <NavLink to="/login" overrides={navLinkOverrides}>
                J'ai déjà un compte
              </NavLink>
            </BottomLinks>
          </>
        )}
      </Form>
    </ModeHeroTemplate>
  );
};

RegisterPageModeHero.displayName = 'RegisterPageModeHero';

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

const FormInput = styled.div`
  width: 100%;
  text-align: left;
`;

const LegalText = styled.div`
  width: 100%;
  text-align: left;
  font-size: 80%;
`;

const Message = styled.div<{$color: string}>`
  width: 100%;
  color: ${props => props.$color};
  text-align: center;
`;

const BottomLinks = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
